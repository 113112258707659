





































import { Component, Vue } from 'vue-property-decorator'
import TermsContent from '@/components/info/TermsContent.vue'

@Component<TermsIndex>({
  components: { TermsContent }
})
export default class TermsIndex extends Vue {
  get xs (): boolean {
    return this.$vuetify.breakpoint.xs
  }
}
